package DataBase

//var server_ip2: String = "http://212.80.206.213"

//var server_ip2:String = "http://31.220.111.31"

var localServerip: String = "172.16.0.10"
var localServer: String = "https://$localServerip"
var server_ip2: String = "https://nihulkav.online"
var server_ip_assistent: String = "https://nihulkav.shop"


//var server_ip2: String = "https://nihulkavtest.online"
//var server_ip_assistent: String = "https://nihulkavtest.online"

//var server_ip2: String = "https://31.220.111.31"
var port: String = "4000"

enum class NewDBAction {
    GET_clients_creation,
    GET_tax_holder,
    GET_clients,
    NEW_clients,
    GET_clients_daily,
    NEW_clients_daily,
    NEW_clients_all_daily,
    UPDATE_clients_all,
    UPDATE_clients,
    UPDATE_clients_static,
    GET_clients_products,
    NEW_clients_products,
    UPDATE_clients_all_products,
    UPDATE_clients_all_products_full,
    UPDATE_supplier_all_products,
    UPDATE_clients_products,
    UPDATE_clients_products_static,
    GET_cost_prices,
    GET_clients_prices,
    GET_clients_priority_prices,
    GET_clients_carteset,
    NEW_clients_carteset,
    GET_edi_clients,
    NEW_edi_clients,
    GET_last_clients_prices,
    NEW_clients_prices,
    UPDATE_clients_prices,
    UPDATE_clients_all_prices,
    UPDATE_suppliers_all_prices,
    GET_clients_pay_pdf,
    GET_clients_pay,
    GET_clients_light_pay,
    NEW_clients_pay,
    UPDATE_clients_pay,
    UPDATE_clients_tax_note,
    UPDATE_all_clients_tax_note,
    GET_last_clients_pay,
    GET_last_clients_checks,
    GET_clients_monthly_cycle,
    GET_clients_monthly_cycle_pdf,
    NEW_clients_monthly_cycle,
    UPDATE_clients_monthly_cycle,
    GET_clients_delivery_note_pdf,
    GET_clients_tax_pdf,
    CALCULATE_client_tax_pdf,
    CALCULATE_client_pay_pdf,
    GET_clients_tax_note,
    GET_clients_light_tax_note,
    NEW_clients_tax_note,
    GET_last_clients_tax_note,
    GET_clients_visit_notes,
    NEW_clients_visit_notes,
    UPDATE_clients_visit_notes,
    GET_clients_notes,
    NEW_clients_notes,
    UPDATE_clients_notes,
    GET_last_clients_notes,
    GET_clients_base_order_notes,
    NEW_clients_base_order_notes,
    GET_clients_order_notes,
    GET_last_updated_clients_order_notes,
    GET_last_updated_clients,
    NEW_clients_order_notes,
    UPDATE_clients_order_notes,
    GET_last_clients_order_notes,
    GET_suppliers,
    NEW_suppliers,
    UPDATE_suppliers,
    UPDATE_suppliers_static,
    GET_suppliers_products,
    NEW_suppliers_products,
    UPDATE_suppliers_products,
    UPDATE_static_suppliers_products,
    GET_suppliers_prices,
    GET_last_supplier_prices,
    NEW_suppliers_prices,
    UPDATE_suppliers_prices,
    GET_suppliers_debt,
    GET_suppliers_monthly_cycle_pdf,
    NEW_suppliers_debt,
    UPDATE_suppliers_monthly_cycle,
    GET_suppliers_notes,
    NEW_suppliers_notes,
    UPDATE_suppliers_notes,
    GET_last_suppliers_notes,
    GET_suppliers_order_notes,
    NEW_suppliers_order_notes,
    UPDATE_suppliers_order_notes,
    GET_last_suppliers_order_notes,
    GET_storenext_config,
    GET_hashavshevet_config,
    CALCULATE_client_carteset_pdf,
    CALCULATE_client_prices_pdf,
    CALCULATE_client_delivery,
    CALCULATE_supplier_delivery,
    CALCULATE_document_sum,
    CALCULATE_data_account_manager,
    CALCULATE_client_debt_xsl,
    CALCULATE_client_edi_note,
    GET_client_edi_note,
    NEW_client_edi_note,
    UPDATE_client_edi_note,
    GET_taklit_products,
    UPDATE_order_products_all,
    GET_order_products,
    NEW_order_products,
    GET_client_product_img,
    GET_client_product_imgs_meta,
    GET_client_product_imgs,
    CALCULATE_taklit,
    GET_cofc_client,
    GET_cofc_agent,
    GET_cofc_full,
    GET_cofc_product,
    GET_cofc_order_product,
    GET_cofc_client_price,
    GET_cofc_order,
    GET_cofc_product_image,
    GET_cofc_product_image_finder,
    NEW_cofc_order,
    UPDATE_cofc_order,
    GET_cofc_info,
    register_cofc_info,
    USER_INFO,
    USER_COFC_CONF,
    USER_COFC_CONF_NORMAL,
    USER_COFC,
    DOCUMENTS_ZIP,
    API_EXCEL_ZIP,
    API_HASHAVSHEVET_ZIP,
    GET_agents,
    LOGIN,
    SEND_AUTO_MAIL,
    SEND_API_REQ,
    LOAD_XLSX,
    UPLOAD_IMAGE,
    UPLOAD_LOGO,
    UPLOAD_IMAGE_SIGN,
    UPLOAD_IMAGE_PHOTO,
    GET_IMAGE_SIGN,
    GET_IMAGE_PHOTO,
    GET_SIGN,
    REFRESH_TOKEN,
    UPDATE_APP,
    TAKANON_PDF,
    GET_OPTIMIZED_DV,
    GET_OPTIMIZED_DV_3,
    GET_OPTIMIZED_NOTE_FINAL,
    GET_COFC_OPTIMIZED_DV,
    GET_OPTIMIZED_BAD_DV,
    API_GET_NOTE,
    API_SET_NOTE,
    API_SEND_CALL,
    API_READ_CALL,
    API_CONFIG,
    CALCULATE_client_order_pdf,
    CALCULATE_client_cofc_order_pdf,
    NEW_clients_all_orders,
    NEW_clients_all_notes,
    NEW_clients_all_tax_notes,
    NEW_clients_all_carteset_value,
    NEW_all_msg_tracker,
    get_msg_tracker,
    MASS_DOC_API,
    GET_OPTIMIZED_CARTESET_VALUE,
    CLIENTS_XSLX,
    PRODUCTS_XSLX,
    LOGO,
    LOGO_COFC,
    QUERY_SUM_PDF,
    DRIVER_ORDER_SUM_PDF,
    EDI_100_CALC,
    GET_OPTIMIZIED_FLAT_NOTE,
    NEW_USER,
    UPDATE_USERS,
    GET_CLIENT_VISIT,
    GET_CLIENT_DEBTS,
    GET_CLIENT_VISITATION_OPTIMIZED,
    GET_LAST_CLIENT_VISITATION_OPTIMIZED,
    GET_CLIENT_PAY_DETAILS,
    GET_OPTIMIZED_NOTES,
    REPORT_ERROR,
    GET_INVENTORY,
    INSERT_INVENTORY,
    CLEAR_INVENTORY,
    SEND_MSG_ALL,
    GET_PRODUCT_AVAILABLE,
    GET_COFC_PRODUCT_AVAILABLE,
    NEW_ALL_PRODUCT_AVAILABLE,
    GET_CLIENT_CP_DATA,
    NEW_CLIENT_CP_DATA,
    REPORTS_PDF,
    HASH_IL_TOKEN,
    HASH_IL_UPDATE,
    HASH_IL_SIGN,
    RESET_WHATSAPP_CONN;

    fun getOLDURL(): String {
        when (this) {

        }
        return ""
    }

    fun getURL(local: Boolean = false): String {
        val api = if (!local) server_ip2 else localServer
        val api_calculate = if (!local) server_ip_assistent else localServer
        when (this) {
            GET_tax_holder -> return "$api:${port}/users/tax"
            GET_clients_creation -> return "$api:${port}/clients/creation"
            GET_clients -> return "$api:${port}/clients"
            NEW_clients -> return "$api:${port}/clients/new"
            GET_clients_daily -> return "$api:${port}/clients/daily"
            NEW_clients_daily -> return "$api:${port}/clients/daily/new"
            NEW_clients_all_daily -> return "$api:${port}/clients/daily/all/new"
            UPDATE_clients_all -> return "$api:${port}/clients/update/all"
            UPDATE_clients -> return "$api:${port}/clients/update"
            UPDATE_clients_static -> return "$api:${port}/clients/update/static"
            GET_cost_prices -> return "$api:${port}/clients/cost"
            GET_clients_products -> return "$api:${port}/clients/products"
            NEW_clients_products -> return "$api:${port}/clients/products/new"
            UPDATE_clients_all_products -> return "$api:${port}/clients/products/all/update"
            UPDATE_clients_all_products_full -> return "$api:${port}/clients/products/all/update_save"
            UPDATE_supplier_all_products -> return "$api:${port}/suppliers/products/all/update"
            UPDATE_clients_products -> return "$api:${port}/clients/products/update"
            UPDATE_clients_products_static -> return "$api:${port}/clients/products/update/static"
            GET_clients_carteset -> return "$api:${port}/clients/carteset"
            NEW_clients_carteset -> return "$api:${port}/clients/carteset/new"
            GET_edi_clients -> return "$api:${port}/clients/edi"
            NEW_edi_clients -> return "$api:${port}/clients/edi/new"
            NEW_all_msg_tracker -> return "$api:${port}/msg/tracker/new"
            get_msg_tracker -> return "$api:${port}/msg/tracker/get"
            GET_clients_prices -> return "$api:${port}/clients/prices"
            GET_clients_priority_prices -> return "$api:${port}/clients/prices_priority"
            GET_last_clients_prices -> return "$api:${port}/clients/prices/last"
            NEW_clients_prices -> return "$api:${port}/clients/prices/new"
            UPDATE_clients_prices -> return "$api:${port}/clients/prices/new"
            UPDATE_clients_all_prices -> return "$api:${port}/clients/prices/all/new"
            UPDATE_suppliers_all_prices -> return "$api:${port}/suppliers/prices/all/new"
            GET_clients_pay_pdf -> return "$api_calculate:${port}/clients/pay/pdf"
            GET_clients_pay -> return "$api:${port}/clients/pay"
            GET_clients_light_pay -> return "$api:${port}/clients/lightpay"
            NEW_clients_pay -> return "$api:${port}/clients/pay/new"
            UPDATE_clients_pay -> return "$api:${port}/clients/pay/update"
            UPDATE_clients_tax_note -> return "$api:${port}/clients/tax_note/update"
            UPDATE_all_clients_tax_note -> return "$api:${port}/clients/update/alltaxnote"
            GET_last_clients_pay -> return "$api:${port}/clients/pay/last"
            GET_last_clients_checks -> return "$api:${port}/clients/pay/checks"
            GET_clients_monthly_cycle -> return "$api:${port}/clients/monthly_cycle"
            GET_clients_monthly_cycle_pdf -> return "$api:${port}/clients/monthly_cycle/pdf"
            NEW_clients_monthly_cycle -> return "$api:${port}/clients/monthly_cycle/new"
            UPDATE_clients_monthly_cycle -> return "$api:${port}/clients/monthly_cycle/new"
            GET_clients_delivery_note_pdf -> return "$api_calculate:${port}/clients/calculate/delivery_note_pdf"
            GET_clients_tax_pdf -> return "$api_calculate:${port}/clients/tax_note/pdf"
            CALCULATE_client_tax_pdf -> return "$api_calculate:${port}/clients/calculate/tax_note"
            CALCULATE_client_pay_pdf -> return "$api_calculate:${port}/clients/calculate/pay_note"
            GET_clients_tax_note -> return "$api:${port}/clients/tax_note"
            GET_clients_light_tax_note -> return "$api:${port}/clients/tax_note_light"
            NEW_clients_tax_note -> return "$api:${port}/clients/tax_note/new"
            GET_last_clients_tax_note -> return "$api:${port}/clients/tax_note/last"
            GET_clients_notes -> return "$api:${port}/clients/notes"
            GET_clients_visit_notes -> return "$api:${port}/clients/visit_notes/get"
            NEW_clients_visit_notes -> return "$api:${port}/clients/visit_notes/new"
            UPDATE_clients_visit_notes -> return "$api:${port}/clients/visit_notes/update"
            NEW_clients_notes -> return "$api:${port}/clients/notes/new"
            UPDATE_clients_notes -> return "$api:${port}/clients/notes/update"
            GET_last_clients_notes -> return "$api:${port}/clients/notes/last"
            GET_clients_order_notes -> return "$api:${port}/clients/order"
            GET_last_updated_clients_order_notes -> return "$api:${port}/clients/last_orders_for_date"
            GET_last_updated_clients -> return "$api:${port}/clients/last_updated"
            GET_clients_base_order_notes -> return "$api:${port}/clients/order/base"
            NEW_clients_base_order_notes -> return "$api:${port}/clients/order/base/new"
            NEW_clients_order_notes -> return "$api:${port}/clients/order/new"
            UPDATE_clients_order_notes -> return "$api:${port}/clients/order/update"
            GET_last_clients_order_notes -> return "$api:${port}/clients/order/last"
            GET_suppliers -> return "$api:${port}/suppliers"
            NEW_suppliers -> return "$api:${port}/suppliers/new"
            UPDATE_suppliers -> return "$api:${port}/suppliers/update"
            UPDATE_suppliers_static -> return "$api:${port}/suppliers/update/static"
            GET_suppliers_products -> return "$api:${port}/suppliers/products"
            NEW_suppliers_products -> return "$api:${port}/suppliers/products/new"
            UPDATE_suppliers_products -> return "$api:${port}/suppliers/products/update"
            UPDATE_static_suppliers_products -> return "$api:${port}/suppliers/products/update/static"
            GET_suppliers_prices -> return "$api:${port}/suppliers/prices"
            GET_last_supplier_prices -> return "$api:${port}/suppliers/prices/last"
            NEW_suppliers_prices -> return "$api:${port}/suppliers/prices/new"
            UPDATE_suppliers_prices -> return "$api:${port}/suppliers/prices/update"
            GET_suppliers_monthly_cycle_pdf -> return "$api:${port}/suppliers/monthly_cycle/pdf"
            GET_suppliers_debt -> return "$api:${port}/suppliers/debt"
            NEW_suppliers_debt -> return "$api:${port}/suppliers/debt/new"
            UPDATE_suppliers_monthly_cycle -> return "$api:${port}/suppliers/debt/new"
            GET_suppliers_notes -> return "$api:${port}/suppliers/notes"
            NEW_suppliers_notes -> return "$api:${port}/suppliers/notes/new"
            UPDATE_suppliers_notes -> return "$api:${port}/suppliers/notes/update"
            GET_last_suppliers_notes -> return "$api:${port}/suppliers/notes/last"
            GET_suppliers_order_notes -> return "$api:${port}/suppliers/order"
            NEW_suppliers_order_notes -> return "$api:${port}/suppliers/order/new"
            UPDATE_suppliers_order_notes -> return "$api:${port}/suppliers/order/update"
            GET_last_suppliers_order_notes -> return "$api:${port}/suppliers/order/last"
            RESET_WHATSAPP_CONN -> return "$api:${port}/whatsapp/refresh"
            CALCULATE_client_carteset_pdf -> return "$api_calculate:${port}/clients/calculate/carteset_pdf"
            CALCULATE_client_prices_pdf -> return "$api_calculate:${port}/clients/calculate/price_pdf"
            CALCULATE_client_delivery -> return "$api_calculate:${port}/clients/calculate/delivery_by_dates"
            CALCULATE_supplier_delivery -> return "$api_calculate:${port}/suppliers/calculate/delivery_by_dates"
            CALCULATE_document_sum -> return "$api_calculate:${port}/clients/calculate/document_sum"
            CALCULATE_data_account_manager -> return "$api_calculate:${port}/clients/calculate/account_manager_data"
            CALCULATE_client_debt_xsl -> return "$api_calculate:${port}/clients/calculate/depts"
            CALCULATE_client_edi_note -> return "$api:${port}/clients/calculate/edi_note"
            GET_client_edi_note -> return "$api:${port}/clients/edi/notes"
            NEW_client_edi_note -> return "$api:${port}/clients/edi/notes/new"
            UPDATE_client_edi_note -> return "$api:${port}/clients/edi/notes/update"
            GET_taklit_products -> return "$api:${port}/taklit"
            GET_order_products -> return "$api:${port}/order/product"
            NEW_order_products -> return "$api:${port}/order/product/new"
            UPDATE_order_products_all -> return "$api:${port}/order/product/update_all"
            CALCULATE_taklit -> return "$api_calculate:${port}/taklit_calc"
            GET_cofc_client -> return "$api:${port}/cofc/client"
            GET_cofc_agent -> return "$api:${port}/cofc/agents"
            GET_cofc_product -> return "$api:${port}/cofc/products"
            GET_cofc_full -> return "$api:${port}/cofc/full"
            GET_cofc_order_product -> return "$api:${port}/cofc/order/product"
            GET_cofc_client_price -> return "$api:${port}//cofc/prices"
            GET_cofc_order -> return "$api:${port}/cofc/order"
            GET_cofc_product_image -> return "$api_calculate:${port}//cofc/products/image"
            GET_cofc_product_image_finder -> return "$api_calculate:${port}/cofc/products/image_s_find"
            GET_client_product_img -> return "$api_calculate:${port}/clients/products/image"
            GET_client_product_imgs -> return "$api_calculate:${port}/clients/products/image_s"
            GET_client_product_imgs_meta -> return "$api_calculate:${port}/clients/products/image_s_find"
            NEW_cofc_order -> return "$api:${port}/cofc/order/new"
            UPDATE_cofc_order -> return "$api:${port}/cofc/order/update"
            GET_cofc_info -> return "$api:${port}/cofc/get_identity"
            register_cofc_info -> return "$api:${port}/cofc/register"
            LOGIN -> return "$api:${port}/login"
            GET_agents -> return "$api:${port}/agents"
            USER_INFO -> return "$api:${port}/users"
            USER_COFC_CONF -> return "$api:${port}/cofc/users/conf"
            USER_COFC_CONF_NORMAL -> return "$api:${port}/users/conf"
            USER_COFC -> return "$api:${port}/cofc/users"
            UPLOAD_IMAGE -> return "$api_calculate:${port}/product/image_upload"
            UPLOAD_LOGO -> return "$api_calculate:${port}/logo_upload"
            UPLOAD_IMAGE_SIGN -> return "$api:${port}/clients/note/sign_upload"
            UPLOAD_IMAGE_PHOTO -> return "$api:${port}/clients/note/photo_upload"
            GET_IMAGE_SIGN -> return "$api_calculate:${port}/clients/note/sign_get_img"
            GET_IMAGE_PHOTO -> return "$api_calculate:${port}/clients/note/photo_get_img"
            GET_SIGN -> return "$api:${port}/clients/note/sign_get_data"
            DOCUMENTS_ZIP -> return "$api_calculate:${port}/calculate/document_zip"
            API_EXCEL_ZIP -> return "$api_calculate:${port}/clients/calculate/apiexcel"
            API_HASHAVSHEVET_ZIP -> return "$api_calculate:${port}/clients/calculate/apihashavshevet"
            UPDATE_APP -> return "https://nihulkav.online:${port}/download"
            GET_storenext_config -> return "$api:${port}/clients/storenext/config"
            GET_hashavshevet_config -> return "$api:${port}/clients/hashavshevet/config"
            REFRESH_TOKEN -> return "$api:${port}/token/refresh"
            TAKANON_PDF -> return "$api:${port}/takanon/pdf"
            GET_OPTIMIZED_DV -> return "$api:${port}/clients/optimized/delivery_notes"
            GET_OPTIMIZED_DV_3 -> return "$api:${port}/clients/optimized/delivery_notes3"
            GET_OPTIMIZED_NOTE_FINAL -> return "$api:${port}/clients/optimized/notes"
            GET_COFC_OPTIMIZED_DV -> return "$api:${port}/cofc/clients/optimized/delivery_notes"
            GET_OPTIMIZED_BAD_DV -> return "$api:${port}/clients/optimized/delivery_notes_bad"
            API_GET_NOTE -> return "$api:${port}/clients/api/get_note"
            API_SET_NOTE -> return "$api:${port}/clients/api/set_note"
            API_SEND_CALL -> return "$api:${port}/clients/api/send_call"
            API_READ_CALL -> return "$api:${port}/clients/api/read_call"
            API_CONFIG -> return "$api:${port}/clients/api/config"
            GET_CLIENT_CP_DATA -> return "$api:${port}/clients/cp_data"
            NEW_CLIENT_CP_DATA -> return "$api:${port}/clients/cp_data_set"
            CALCULATE_client_order_pdf -> return "$api_calculate:${port}/clients/calculate/order_pdf"
            CALCULATE_client_cofc_order_pdf -> return "$api_calculate:${port}/cofc/clients/calculate/order_pdf"
            NEW_clients_all_orders -> return "$api:${port}/clients/order/all/new"
            NEW_clients_all_notes -> return "$api:${port}/clients/notes/all/new"
            NEW_clients_all_tax_notes -> return "$api:${port}/clients/tax_note/all/new"
            NEW_clients_all_carteset_value -> return "$api:${port}/clients/carteset/all/new"
            MASS_DOC_API -> return "$api_calculate:${port}/clients/calculate/mass_doc_pdf"
            GET_OPTIMIZED_CARTESET_VALUE -> return "$api:${port}/clients/optimized/carteset_value"
            CLIENTS_XSLX -> return "$api_calculate:${port}/clients/calculate/clientxslx"
            PRODUCTS_XSLX -> return "$api_calculate:${port}/clients/calculate/productsxslx"
            LOGO -> return "$api_calculate:${port}/user/logo"
            LOGO_COFC -> return "$api_calculate:${port}/cofc/users/logo"
            QUERY_SUM_PDF -> return "$api_calculate:${port}/clients/calculate/query_sum"

            DRIVER_ORDER_SUM_PDF -> return "$api_calculate:${port}/clients/calculate/driver_query_sum"
            EDI_100_CALC -> return "$api_calculate:${port}/clients/calculate/edi"
            GET_OPTIMIZIED_FLAT_NOTE -> return "$api:${port}/clients/optimized/flat_delivery_notes"
            NEW_USER -> return "$api:${port}/users/new"
            UPDATE_USERS -> return "$api:${port}/users/update"
            GET_INVENTORY -> return "$api:${port}/products/inventory"
            INSERT_INVENTORY -> return "$api:${port}/products/all_insert_inventory"
            CLEAR_INVENTORY-> return "$api:${port}/products/clear_inventory"
            GET_CLIENT_VISIT -> return "$api:${port}/clients/notes/reach"
            GET_CLIENT_DEBTS -> return "$api:${port}/clients/optimized/client_debt2"
            GET_CLIENT_VISITATION_OPTIMIZED -> return "$api:${port}/clients/optimized/visitation"
            GET_LAST_CLIENT_VISITATION_OPTIMIZED -> return "$api:${port}/clients/optimized/visitation/last"
            GET_CLIENT_PAY_DETAILS -> return "$api:${port}/clients/optimized/client_pays"
            GET_OPTIMIZED_NOTES -> return "$api:${port}/clients/optimized/get_delivery_notes"
            SEND_AUTO_MAIL -> return "$api_calculate:${port}/clients/calculate/mail_service"
            SEND_API_REQ -> return "$api_calculate:${port}/clients/calculate/api_service"
            LOAD_XLSX -> return "$api_calculate:${port}/clients/load/xlsx"
            REPORT_ERROR -> return "https://www.solvitc.online:4000/crash/report"
            REPORTS_PDF -> return "$api_calculate:${port}/clients/calculate/reports_pdf"
            SEND_MSG_ALL -> return "$api_calculate:${port}/send_msg_service"
            GET_PRODUCT_AVAILABLE -> return "$api:${port}/clients/available"
            GET_COFC_PRODUCT_AVAILABLE -> return "$api:${port}/cofc/product/available"
            NEW_ALL_PRODUCT_AVAILABLE -> return "$api:${port}/clients/available/all/new"
            HASH_IL_UPDATE -> return "$api:${port}/heshisrael/update"
            HASH_IL_SIGN -> return "$api:${port}/heshisrael/sign"
//            HASH_IL_TOKEN -> return "https://openapi.taxes.gov.il/shaam/tsandbox/longtimetoken/oauth2/token"
            HASH_IL_TOKEN -> return "https://openapi.taxes.gov.il/shaam/production/longtimetoken/oauth2/token"
        }
        return ""
    }

}